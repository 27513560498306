import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import TimezoneSelect from 'react-timezone-select'
import { API_URL } from 'src/services'
import BackButton from '../../../components/button/BackButton'
import CalendarComponent from 'src/components/CalendarComponent'
import { getISOEndDate, getISOStartDate } from 'src/services/timestamp'

interface FormData {
  shlEmail: string
  shlPassword: string
  siteUrl: string
  shl_report_fetch_interval: string
  report_viewer_username: string 
  report_viewer_passwd: string 
  admin_name: string
  email_id: string
  mobile_no: string
  status: number
  address: string
  test_Limit: number
  valid_from: string
  valid_to: string
  password: string
  created_by: string
  company_name: string
  test_Api: string
  api_endpoint: string
  timezone: string // Added for time zone support
}

export interface Admin {
  _id: string
  admin_name: string
  email_id: string
  mobile_no: string
  status: number
  address: string
  company_name: string
  company_logo: string
  test_Limit: number
  test_Api: string
  api_endpoint: string
  valid_from: string
  valid_to: string
  profile_pic: string
  permission: string
  linkedin_url: string
  social_profile: string
  role: string
  username: string
  country: string
  state: string
  timezone: string // Add this line
  report_viewer_username: string 
  report_viewer_passwd: string 
}
const today = new Date()
const formattedToday = getISOStartDate(today)

const CreateSAdmin: React.FC = () => {
  const masterAdminId = '66123fc04c42127bb0a65750'
  const [selectedTime, setSelectedTime] = useState('20');
  const [customTime, setCustomTime] = useState('');
  
  const handleTimeChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setSelectedTime(event.target.value);
  };
  
  const handleCustomTimeChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setCustomTime(event.target.value);
  };

  const [formData, setFormData] = useState<FormData>({
    admin_name: '',
    email_id: '',
    mobile_no: '',
    status: 1,
    address: '',
    company_name: '',
    test_Limit: 0,
    test_Api: '',
    valid_from: formattedToday,
    valid_to: getISOEndDate(today),
    password: '',
    created_by: masterAdminId,
    timezone: '', // Initialize time zone
    api_endpoint: '',
    report_viewer_username: "" ,
    report_viewer_passwd: "" ,
    shlEmail: "",
    shlPassword: "",
    siteUrl: "",
    shl_report_fetch_interval: selectedTime === 'custom' ? customTime : selectedTime,
  })

  const [admins, setAdmins] = useState<Admin[]>([])
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [editingAdminId, setEditingAdminId] = useState<string | null>(null)

  useEffect(() => {
    const fetchAdmins = async () => {
      try {
        const response = await axios.get(`${API_URL}/getSuperAdmins`)
        if (Array.isArray(response.data.data)) {
          setAdmins(response.data.data)
          localStorage.setItem('admins', JSON.stringify(response.data.data))
        } else {
          console.error('Unexpected data format:', response.data)
        }
      } catch (error) {
        console.error('Error fetching admins:', error)
      }
    }

    const savedAdmins = localStorage.getItem('admins')
    if (savedAdmins) {
      setAdmins(JSON.parse(savedAdmins))
    } else {
      fetchAdmins()
    }
  }, [])

  useEffect(() => {
    localStorage.setItem('admins', JSON.stringify(admins))
  }, [admins])

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()

    // Check if email ID is unique
    const isEmailUnique = admins.every(
      admin => admin.email_id !== formData.email_id
    )
    if (!isEmailUnique) {
      alert('This email ID is already in use. Please use a different email ID.')
      return
    }

    if (
      !/^(?=.*[!@#$%^&*])(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*]{8,}$/.test(
        formData.password
      )
    ) {
      alert('Password must be more than 8 characters and include symbols')
      return
    }

    try {
      if (isEditing && editingAdminId) {
        await axios.put(`${API_URL}/SadminUpdate/${editingAdminId}`, formData)
        alert('Admin updated successfully')
        setAdmins(prevAdmins =>
          prevAdmins.map(admin =>
            admin._id === editingAdminId ? { ...admin, ...formData } : admin
          )
        )
      } else {
        const response = await axios.post(`${API_URL}/addSuperAdmin`, formData)
        alert('Admin added successfully')
        setAdmins([...admins, response.data])
      }

      setFormData({
        admin_name: '',
        email_id: '',
        mobile_no: '',
        status: 1,
        address: '',
        company_name: '',
        test_Limit: 0,
        test_Api: '',
        valid_from: formattedToday,
        valid_to: getISOEndDate(today),
        password: '',
        created_by: masterAdminId,
        timezone: '', // Reset time zone
        api_endpoint: '',
        report_viewer_username: "", 
        report_viewer_passwd: "", 
        shlEmail: "",
        shlPassword: "",
        siteUrl: "",
        shl_report_fetch_interval: ""
      })
      setIsEditing(false)
      setEditingAdminId(null)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Error adding or updating admin:', error)
        console.error('Error response data:', error.response?.data)
      } else {
        console.error('Unexpected error:', error)
      }
      alert('Failed to add or update admin')
    }
  }

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const handleEdit = (admin: Admin) => {
    setIsEditing(true)
    setEditingAdminId(admin._id)
    setFormData({
      admin_name: admin.admin_name,
      email_id: admin.email_id,
      mobile_no: admin.mobile_no,
      status: admin.status,
      address: admin.address,
      company_name: admin.company_name,
      test_Limit: admin.test_Limit,
      test_Api: admin.test_Api,
      api_endpoint: admin.api_endpoint,
      valid_from: admin.valid_from,
      valid_to: admin.valid_to,
      password: '',
      created_by: masterAdminId,
      timezone: admin.timezone || '',
      report_viewer_username: "", 
      report_viewer_passwd: "", 
      shlEmail: "",
      shlPassword: "",
      siteUrl: "",
      shl_report_fetch_interval: ""
    })
  }

  const handleTimezoneChange = async (timezone: any) => {
    setFormData(prevState => ({
      ...prevState,
      timezone: timezone.value,
    }))

    // Update the time zone for the super admin
    try {
      await axios.put(`${API_URL}/updateSuperAdminTimezone`, {
        adminId: masterAdminId,
        timezone: timezone.value,
      })
      alert('Time zone updated successfully')
    } catch (error) {
      console.error('Error updating time zone:', error)
      alert('Failed to update time zone')
    }
  }

  return (
    <div className="p-10">
      <form
        onSubmit={handleSubmit}
        className="flex flex-col p-10 items-center justify-center min-h-screen w-full rounded-md bg-white shadow-md">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 w-full pb-2">
          <BackButton />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 w-full">
          <div className="flex flex-col">
            <label
              className="mb-2 text-sm font-medium text-black"
              htmlFor="admin_name">
              Admin Name
            </label>
            <input
              type="text"
              name="admin_name"
              value={formData.admin_name}
              onChange={handleChange}
              placeholder="Name"
              required
              className="p-3 border outline-1 outline rounded-lg "
            />
          </div>

          <div className="flex flex-col">
            <label
              className="mb-2 text-sm font-medium text-black"
              htmlFor="email_id">
              Email
            </label>
            <input
              type="email"
              name="email_id"
              value={formData.email_id}
              onChange={handleChange}
              placeholder="Email"
              required
              className="p-3 border outline-1 outline rounded-lg "
            />
          </div>

          <div className="flex flex-col">
            <label
              className="mb-2 text-sm font-medium text-black"
              htmlFor="timeZone">
              Time Zone
            </label>
            <TimezoneSelect
              value={formData.timezone}
              onChange={handleTimezoneChange}
              className="p-3 border outline-1 outline rounded-lg "
            />
          </div>

          <div className="flex flex-col">
            <label
              className="mb-2 text-sm font-medium text-black"
              htmlFor="status">
              Status
            </label>
            <select
              name="status"
              value={formData.status}
              onChange={handleChange}
              className="p-3 border outline-1 outline rounded-lg ">
              <option value={1}>Active</option>
              <option value={0}>Inactive</option>
            </select>
          </div>

          <div className="flex flex-col">
            <label
              className="mb-2 text-sm font-medium text-black"
              htmlFor="company_name">
              Company Name
            </label>
            <input
              type="text"
              name="company_name"
              value={formData.company_name}
              onChange={handleChange}
              placeholder="Company Name"
              className="p-3 border outline-1 outline rounded-lg "
            />
          </div>

          <div className="flex flex-col">
            <label
              className="mb-2 text-sm font-medium text-black"
              htmlFor="address">
              Address
            </label>
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleChange}
              placeholder="Address"
              className="p-3 border outline-1 outline rounded-lg "
            />
          </div>
          <div className="flex flex-col">
            <label
              className="mb-2 text-sm font-medium text-black"
              htmlFor="shlEmail">
              SHL Email ID
            </label>
            <input
              type="text"
              name="shlEmail"
              value={formData.shlEmail}
              onChange={handleChange}
              placeholder="SHL Account Email Id"
              className="p-3 border outline-1 outline rounded-lg "
            />
          </div>          
          <div className="flex flex-col">
            <label
              className="mb-2 text-sm font-medium text-black"
              htmlFor="shlPassword">
              SHL Password
            </label>
            <input
              type="text"
              name="shlPassword"
              value={formData.shlPassword}
              onChange={handleChange}
              placeholder="SHL Password"
              className="p-3 border outline-1 outline rounded-lg "
            />
          </div>          
          <div className="flex flex-col">
            <label
              className="mb-2 text-sm font-medium text-black"
              htmlFor="siteUrl">
              SHL Report Site URL
            </label>
            <input
              type="text"
              name="siteUrl"
              value={formData.siteUrl}
              onChange={handleChange}
              placeholder="SHL Report Site URL"
              className="p-3 border outline-1 outline rounded-lg "
            />
          </div>
          <div className="flex flex-col">
            <label
              className="mb-2 text-sm font-medium text-black"
              htmlFor="shl-auto-schedule"
            >
              SHL Automated Report Fetch Interval
            </label>
            
            <select
              name="time-dropdown"
              value={selectedTime}
              onChange={handleTimeChange}
              className="p-3 border bg-white outline-1 outline rounded-lg"
            >
              <option value="20">20 mins</option>
              <option value="30">30 mins</option>
              <option value="60">60 mins</option>
              <option value="custom">Custom Time</option>
            </select>

            {selectedTime === 'custom' && (
              <input
                type="text"
                name="custom-time"
                value={customTime}
                onChange={handleCustomTimeChange}
                placeholder="Enter custom time"
                className="w-full border  rounded-lg p-2 mt-2"
              />
            )}
          </div>


          <div className="flex flex-col">
            <label
              className="mb-2 text-sm font-medium text-black"
              htmlFor="test_Limit">
              Test Limit
            </label>
            <input
              type="number"
              name="test_Limit"
              value={formData.test_Limit}
              onChange={handleChange}
              placeholder="Test Limit"
              className="p-3 border outline-1 outline rounded-lg "
            />
          </div>

          <div className="flex flex-col">
            <label
              className="mb-2 text-sm font-medium text-black"
              htmlFor="test_Api">
              Scheduling API key
            </label>
            <input
              type="text"
              name="test_Api"
              value={formData.test_Api}
              onChange={handleChange}
              placeholder="Test API"
              className="p-3 border outline-1 outline rounded-lg "
            />
          </div>
          <div className="flex flex-col">
            <label
              className="mb-2 text-sm font-medium text-black"
              htmlFor="api_endpoint">
              Scheduling API URL
            </label>
            <input
              type="text"
              name="api_endpoint"
              value={formData.api_endpoint}
              onChange={handleChange}
              placeholder="Test API Endpoint"
              className="p-3 border outline-1 outline rounded-lg "
            />
          </div>
          <div className="flex flex-col">
            <label
              className="mb-2 text-sm font-medium text-black"
              htmlFor="report_viewer_username">
              Report Viewer Username
            </label>
            <input
              type="text"
              name="report_viewer_username"
              value={formData.report_viewer_username}
              onChange={handleChange}
              placeholder="Report Viewer Username"
              className="p-3 border outline-1 outline rounded-lg "
            />
          </div>
          <div className="flex flex-col">
            <label
              className="mb-2 text-sm font-medium text-black"
              htmlFor="report_viewer_passwd">
              Report Viewer Password
            </label>
            <input
              type="password"
              name="report_viewer_passwd"
              value={formData.report_viewer_passwd}
              onChange={handleChange}
              placeholder="Report Viewer Password"
              className="p-3 border outline-1 outline rounded-lg "
            />
          </div>
          <div className="flex flex-col">
            <label
              className="mb-2 text-sm font-medium text-black"
              htmlFor="valid_from">
              Valid From
            </label>
            <CalendarComponent
              disabled={false}
              selected={formData.valid_from}
              name="valid_from"
              handleChange={handleChange}
              type="start"
            />
          </div>

          <div className="flex flex-col">
            <label
              className="mb-2 text-sm font-medium text-black"
              htmlFor="valid_to">
              Valid To
            </label>
            <CalendarComponent
              disabled={false}
              selected={formData.valid_to}
              name="valid_to"
              handleChange={handleChange}
              type="end"
            />
          </div>
          <div className="flex flex-col">
            <label
              className="mb-2 text-sm font-medium text-black"
              htmlFor="mobile_no">
              Mobile
            </label>
            <input
              type="text"
              name="mobile_no"
              value={formData.mobile_no}
              onChange={handleChange}
              placeholder="Mobile"
              required
              className="p-3 border outline-1 outline rounded-lg "
            />
          </div>

          <div className="flex flex-col">
            <label
              className="mb-2 text-sm font-medium text-black"
              htmlFor="password">
              Password
            </label>
            <div className="relative">
              <input
                type={showPassword ? 'text' : 'password'}
                name="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="Password"
                required
                className="p-3 w-full  border outline-1 outline rounded-lg "
              />
              <button
                type="button"
                onClick={handlePasswordVisibility}
                className="absolute inset-y-0 right-0 flex items-center px-3 text-gray-500">
                <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
              </button>
            </div>
          </div>
        </div>

        <button
          type="submit"
          className="mt-6 px-6 py-3 bg-blue-500 text-white rounded-lg shadow hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500">
          {isEditing ? 'Update Admin' : 'Add Admin'}
        </button>
      </form>
    </div>
  )
}

export default CreateSAdmin
