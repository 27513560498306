import React, { useEffect, useState } from "react";
import Table from "../../components/table/Table";
import { API_URL } from "src/services";

interface Assessment {
  __parentArray: any;
  _id: string;
  program_name: string; // Added program_name
  module_name: string; // Added module_name
  type: string;
  activity_start_date: string;
  activity_end_date: string;
  status: number;
  data: {
    no_of_attempt?: string;
    test_id: string;
    test_name?: string;
    assessment_engine?: string;
    activity_url?: string;
    activity_description?: string;
    activity_name?: string;
    activity_video_id?: string;
  };
  result_published: boolean;
  order: number;
}

const ConfirmationModal = ({ isOpen, onClose, onConfirm, assessments }: { 
  isOpen: boolean; 
  onClose: () => void; 
  onConfirm: () => void; 
  assessments: Assessment[]; 
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-4 rounded-lg shadow-lg">
        <h2 className="text-lg font-bold">Confirm Publish Results</h2>
        <div>
          {assessments.map(assessment => (
            <div key={assessment._id}>
              <p>Program Name: {assessment.program_name}</p> {/* Display program name */}
              <p>Module Name: {assessment.module_name}</p> {/* Display module name */}
              <p>Test Name: {assessment.data.test_name || 'N/A'}</p>
              <p>Test ID: {assessment.data.test_id}</p>
              <p>Start Date: {assessment.activity_start_date}</p>
              <p>End Date: {assessment.activity_end_date}</p>
              <hr />
            </div>
          ))}
        </div>
        <div className="flex justify-end mt-4">
          <button onClick={onClose} className="mr-2 p-2 bg-gray-300 rounded">Cancel</button>
          <button onClick={onConfirm} className="p-2 bg-[#202e59] text-white rounded">Publish</button>
        </div>
      </div>
    </div>
  );
};

export default function ManageResults() {
  const [assessments, setAssessments] = useState<Assessment[]>([]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedAssessments, setSelectedAssessments] = useState<Assessment[]>([]);

  useEffect(() => {
    const fetchAssessments = async () => {
      const user = JSON.parse(localStorage.getItem('user') || '{}'); 
      const superAdmin = user._id;
      try {
        const response = await fetch(`${API_URL}/results_fetch?super_admin=${superAdmin}`);
        const data: Assessment[] = await response.json();
        
        // Flatten data to match new structure
        const flattenedAssessments = data.flatMap(item => 
          item.__parentArray.map((activity: any) => ({
            ...activity,
            program_name: item.program_name, // Add program_name to each assessment
            module_name: item.module_name, // Add module_name to each assessment
          }))
        );

        setAssessments(flattenedAssessments);
      } catch (error) {
        console.error("Error fetching assessments:", error);
      }
    };

    fetchAssessments();
  }, []);

  const handleRowSelect = (id: string, isSelected: boolean) => {
    setSelectedIds(prev =>
      isSelected ? [...prev, id] : prev.filter(selectedId => selectedId !== id)
    );
    setSelectedAssessments(assessments.filter(assessment => 
      isSelected ? assessment._id === id : assessment._id !== id
    ));
  };

  const handlePublish = () => {
    setSelectedAssessments(assessments.filter(assessment => selectedIds.includes(assessment._id)));
    setModalOpen(true);
  };

  const confirmPublish = async () => {
    try {
      const response = await fetch(`${API_URL}/publish_results`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ids: selectedIds }),
      });

      if (response.ok) {
        const updatedAssessments = assessments.filter(assessment => !selectedIds.includes(assessment._id));
        setAssessments(updatedAssessments);
        setSelectedIds([]);
        setSelectedAssessments([]);
      } else {
        console.error("Error publishing assessments");
      }
    } catch (error) {
      console.error("Error during publishing:", error);
    } finally {
      setModalOpen(false);
    }
  };

  const columns = [
    { key: 'program_name', header: 'Program Name' }, // New column for program name
    { key: 'module_name', header: 'Module Name' }, // New column for module name
    { key: 'type', header: 'Type' },
    { key: 'activity_start_date', header: 'Start Date' },
    { key: 'activity_end_date', header: 'End Date' },
    {
      key: 'data.test_name', 
      header: 'Test Name', 
      render: (item: Assessment) => item.data?.test_name || 'N/A'
    },
    {
      key: 'data.test_id', 
      header: 'Test ID', 
      render: (item: Assessment) => item.data?.test_id || 'N/A'
    },
    {
      key: 'data.no_of_attempt', 
      header: 'No. of Attempts', 
      render: (item: Assessment) => item.data?.no_of_attempt || 'N/A'
    },
    {
      key: 'result_published', 
      header: 'Published', 
      render: (item: Assessment) => item.result_published ? 'Yes' : 'No' 
    },
  ];

  return (
    <div>
      <h1 className="text-lg font-bold">Manage Results</h1>
      <Table
        data={assessments}
        columns={columns}
        onRowSelect={handleRowSelect}
        selectedIds={selectedIds}
      />
      <button 
        onClick={handlePublish} 
        disabled={selectedIds.length === 0}
        className="mt-4 p-2 bg-[#202e59] text-white rounded-lg"
      >
        Publish Results
      </button>
      <ConfirmationModal 
        isOpen={modalOpen} 
        onClose={() => setModalOpen(false)} 
        onConfirm={confirmPublish} 
        assessments={selectedAssessments} 
      />
    </div>
  );
}
