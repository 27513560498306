import React, { useContext, useEffect, useMemo, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { ActivityProps, activitySchema } from '../program.interface'
import 'react-datepicker/dist/react-datepicker.css'
import userData from 'src/hooks/useData/useData'
import {
  getISOStartDate,
  isValidEndDate,
  isValidStartDate,
} from 'src/services/timestamp'
import { useLoaderAndToast } from 'src/ToastProvider'
import CalendarComponent from 'src/components/CalendarComponent'
import { isTempID } from '../ActivityCollection'
import axios from 'axios'
import { API_URL, fetchProgramData } from 'src/services'
import { AssessmentOption } from 'src/interfaces/learner'
import { toast } from 'react-toastify'

const today = new Date()
const formattedToday = getISOStartDate(today)

interface IAssessmentData {
  activity_start_date: string;
  activity_end_date: string;
  no_of_attempt: string;
  test_id: string;
  test_name: string;
  score_display: string; // Added score_display
  report: string; // Added report
  description: string;
}

export default function AddAssessmentForm({
  handleSubmit,
  activityData,
  deleteActivityId,
  disabled,
  module_start_date,
  module_end_date,
  programId,
}: ActivityProps) {
  const { user } = userData();
  const { showToast } = useLoaderAndToast();
  const initialActivityData = {
    activity_start_date: activityData.activity_start_date,
    activity_end_date: activityData.activity_end_date,
    no_of_attempt: '',
    test_id: '',
    test_name: '',
    score_display: 'instant', // Default value
    report: '',
    description: '',
   } // Default value changed to empty
  const [formData, setFormData] = useState<IAssessmentData>(
    activityData?.data && Object.keys(activityData.data).length === 0
      ? initialActivityData
      : {
          activity_start_date: activityData.activity_start_date,
          activity_end_date: activityData.activity_end_date,
          ...activityData.data,
        }
  )

  const [assessmentsList, setAssessments] = useState<AssessmentOption[]>([])

  const fetchAssessments = async () => {
    try {
      const response = await fetch(`${API_URL}/getassessment`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      });
      const data = await response.json();
      if (data && data.assessments.length !== 0) {
        setAssessments(data.assessments);
      }
    } catch (error) {
      toast.error('Error fetching Assessments List');
      setAssessments([]);
    }
  };

  useEffect(() => {
    void fetchAssessments()
  }, [])

  const sendNotification = async (learnerArray: any[], programId: any) => {
    console.log('Learner Array:', learnerArray)

    try {
      const programData = await fetchProgramData(programId)
      console.log('Fetched Program Data:', programData)

      const currentUserName = localStorage.getItem('user')
      let createdBy = null
      if (currentUserName) {
        try {
          const parsedUserName = JSON.parse(currentUserName)
          createdBy = parsedUserName.admin_name
        } catch (error) {
          console.error('Error parsing current user:', error)
        }
      }

      let { program_name } = programData

      // Customize the telegramData for assessment
      const telegramData = {
        name: program_name,
        test: formData.test_name,
        assessment_end: formData.activity_end_date,
        learners: learnerArray,
        createdBy: createdBy,
        type: 'assessment',
      }

      console.log('Telegram Notification Data:', telegramData) // Log the notification data

      const token = localStorage.getItem('token')
      const headers = {
        Authorization: 'Bearer ' + token,
      }

      try {
        const response = await axios.post(
          `${API_URL}/telegram-program-notification`,
          { ...telegramData },
          { headers }
        )
        console.log('Response from Telegram Notification API:', response)
      } catch (error) {
        console.error('Error sending notification:', error)
      }

      // for (let i = 0; i < learnerArray.length; i++) {
      //   const element = learnerArray[i];
      //   console.log('Processing Learner ID:', element);

      //   try {
      //     const { data } = await axios.post(`${API_URL}/learner_detail_id`, { _id: element }, { headers });
      //     console.log('Learner Details:', data);

      //     if (data.learner.chat_id) {
      //     } else {
      //       console.warn('No chat_id found for learner:', element);
      //     }
      //   } catch (error) {
      //     console.error('Error fetching learner details:', error);
      //   }
      // }
    } catch (error) {
      console.error(
        'Error fetching program data or sending notification:',
        error
      )
    }
  }

  const handleAsessmentSubmit = async (path: any) => {
    validation()

    const payload: activitySchema = {
      ...activityData,
      activity_start_date: formData.activity_start_date,
      activity_end_date: formData.activity_end_date,
      result_published:false,
      score_display: formData.score_display,
      report: formData.report,
      description: formData.description,
      data: {
        no_of_attempt: formData.no_of_attempt,
        test_id: formData.test_id,
        test_name: formData.test_name,
      },
    };

    try {
      handleSubmit(payload) // Uncomment if you have a handleSubmit function to save the assessment

      const programData = await fetchProgramData(programId)
      console.log(programData, 'program data')

      const studentsResponse = await axios.get(`${API_URL}/fetchlearners`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })

      const learnerList = studentsResponse.data.learner_list
      let learnerArray: any[] = []

      for (let i = 0; i < learnerList.length; i++) {
        const element = learnerList[i]
        // console.log(element, 'element')

        if (element.programs_assigned.length) {
          for (let i = 0; i < element.programs_assigned.length; i++) {
            const ele = element.programs_assigned[i]

            if (ele.program_id == programData._id) {
              console.log(ele.program_id, 'ele')
              console.log(programData._id, 'programData._id')
              console.log('hit')
              console.log(element._id)

              if (!learnerArray.includes(programData._id)) {
                learnerArray.push(element._id)
              }
            }
          }
        }
      }

      console.log('Students Response:', learnerArray)

      if (path === 'learnerAssessmentsAssign') {
        await sendNotification(learnerArray, programId)
      }

      showToast('Assessment added and notification sent!', 'success')

      // Send calendar data
      const calendarData = {
        title: formData.test_name,
        startDate: formData.activity_start_date,
        endDate: formData.activity_end_date,
        color: 'blue',
        isProgram: false,
        flag: 'assessment',
        learners: learnerArray,
      }

      const token = localStorage.getItem('token')
      const headers = {
        Authorization: 'Bearer ' + token,
      }

      try {
        const calendarResponse = await axios.post(
          `${API_URL}/postCalendarData`,
          calendarData,
          { headers }
        )
        console.log('Response from API:', calendarResponse.data)
      } catch (error) {
        console.error('Error sending calendar data:', error)
      }
    } catch (error) {
      console.error('Error saving form:', error);
      showToast('Error saving form.', 'error');
    }
  };

  const validation = () => {
    const errors: string[] = [];

    if (isValidStartDate(formData.activity_start_date, formattedToday) && isTempID(activityData)) {
      const message = 'Start date must be after today.';
      showToast(message, 'error');
      errors.push(message);
    }
    if (
      isValidEndDate(
        formattedToday,
        formData.activity_start_date,
        formData.activity_end_date
      )
    ) {
      const message = 'End date must be after start date and after today.'
      showToast(message, 'error')
      errors.push(message)
    }
    if (formData.description.trim() === '') {
      const message = 'Description is required.';
      showToast(message, 'error');
      errors.push(message);
    }    
    if (
      isValidEndDate(
        formData.activity_end_date,
        activityData.activity_start_date,
        activityData.activity_end_date
      ) &&
      isValidEndDate(
        formData.activity_start_date,
        activityData.activity_start_date,
        activityData.activity_end_date
      )
    ) {
      const message = 'Activity date should be within module date range.'
      showToast(message, 'error')
      errors.push(message)
    }

    if (errors.length > 0) {
      return
    }
  }


  const handleChange = (event: any) => {
    const { name, value } = event.target
    if (name === 'assessment') {
      const assessmentItem = assessmentsList.find(
        item => item.assessment_id.toString() === value.toString()
      )

      setFormData({
        ...formData,
        test_name: assessmentItem?.assessment_name ?? '',
        test_id: value,
      });
    } else if (name === 'score_display') {
      setFormData({
        ...formData,
        [name]: value,
        report: '',
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      })
    }
  }

  const resetForm = () => {
    if (Object.keys(activityData.data).length === 0) {
      setFormData(initialActivityData)
    } else {
      setFormData({
        activity_start_date: activityData.activity_start_date,
        activity_end_date: activityData.activity_end_date,
        ...activityData.data,
      });
    }
  }

  const permission = JSON.parse(localStorage.getItem('user')!).permission
  const disableSaveButton = useMemo(() => {
    return disabled ||
      formData.no_of_attempt === '' ||
      formData.test_id === '' ||
      formData.test_name === '' ||
      formData.score_display === '' ||
      formData.report === '' ||
      !permission.includes('edit_program');
  }, [formData, disabled]);

  const getAvailableReportOptions = () => {
    if (formData.score_display === 'instant') {
      return ['instant','after_deadline', 'manual'];
    }
    if (formData.score_display === 'after_deadline') {
      return ['after_deadline', 'manual'];
    }
    return ['manual'];
  };

  const availableReportOptions = getAvailableReportOptions();

  return (
    <div className="my-2">
      <div className="mb-4 flex flex-col mx-20 border-2 border-black rounded-lg p-2">
        <div className="p-2 w-full">
          <div className="mb-4 flex justify-start">
            <label
              htmlFor="assessment"
              className="block text-gray-700 font-bold mb-2 w-1/3">
              Assessment<span className="text-red-500">*</span>
            </label>
            <select
              id="assessment"
              name="assessment"
              disabled={disabled}
              value={formData.test_id}
              className="bg-gray-300 shadow appearance-none rounded w-full mr-24 py-2 px-3 text-gray-700 leading-tight focus:outline-none"
              onChange={handleChange}>
              <option key="0" value="" disabled hidden>
                Choose Assessment here
              </option>
              {assessmentsList.map(assessment => (
                <option
                  key={assessment.assessment_id}
                  value={assessment.assessment_id.toString()}>
                  {assessment.assessment_name} - {assessment.assessment_id}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4 flex flex-col">
            <label htmlFor="description" className="block text-gray-700 font-bold mb-2">
              Description<span className="text-red-500">*</span>
            </label>
            <textarea
              id="description"
              name="description"
              rows={4}
              required
              disabled={disabled}
              value={formData.description}
              className="bg-gray-300 shadow appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
              onChange={handleChange}
            />
          </div>

          <div className="mb-4 flex justify-start">
            <label htmlFor="no_of_attempt" className="block text-gray-700 font-bold mb-2 w-1/3">
              No of Attempts<span className="text-red-500">*</span>
            </label>
            <select
              id="no_of_attempt"
              name="no_of_attempt"
              disabled={disabled}
              value={formData.no_of_attempt}
              className="bg-gray-300 shadow appearance-none rounded w-full mr-24 py-2 px-3 text-gray-700 leading-tight focus:outline-none"
              onChange={handleChange}>
              <option key="0" value="" disabled hidden>
                Choose Attempts here
              </option>
              {Array.from({ length: 5 }, (_, i) => (
                <option key={i + 1} value={i + 1}>
                  {i + 1}
                </option>
              ))}
            </select>
          </div>

          <div className="flex flex-col">
            <label className="block text-gray-700 font-medium mb-2">Score Display:</label>
            <div className="flex gap-4">
              <label>
                <input
                  type="radio"
                  name="score_display"
                  value="instant"
                  checked={formData.score_display === 'instant'}
                  onChange={handleChange}
                />
                Instant
              </label>
              <label>
                <input
                  type="radio"
                  name="score_display"
                  value="after_deadline"
                  checked={formData.score_display === 'after_deadline'}
                  onChange={handleChange}
                />
                After Deadline
              </label>
              <label>
                <input
                  type="radio"
                  name="score_display"
                  value="manual"
                  checked={formData.score_display === 'manual'}
                  onChange={handleChange}
                />
                Manual
              </label>
            </div>
          </div>

          <div className="flex flex-col mt-4">
            <label className="block text-gray-700 font-medium mb-2">Report Display  :</label>
            <div className="flex gap-4">
              {availableReportOptions.map(option => (
                <label key={option}>
                  <input
                    type="radio"
                    name="report"
                    value={option}
                    checked={formData.report === option}
                    onChange={handleChange}
                    disabled={option === 'after_deadline' && formData.score_display === 'manual'}
                  />
                  {option.charAt(0).toUpperCase() + option.slice(1)}
                </label>
              ))}
            </div>
          </div>

          <div className="flex justify-end text-lg">
            <button
              onClick={handleAsessmentSubmit}
              disabled={disableSaveButton}
              className={`text-white rounded-md px-8 py-2 mr-2 ${
                disableSaveButton
                  ? 'bg-sfl-disabled-grey opacity-25'
                  : 'bg-blue-800'
              }`}>
              Reset
            </button>
            {user.role === 'super_admin' &&
              !disabled &&
              activityData &&
              activityData._id !== undefined && (
                <button
                  onClick={() => {
                    deleteActivityId(activityData?._id ?? '')
                  }}
                  disabled={disabled}
                  className="bg-red-800 text-white rounded-md px-8 py-2 mr-2">
                  Delete
                </button>
              )}

            <button
              type="button"
              disabled={disableSaveButton}
              onClick={() => handleAsessmentSubmit('learnerAssessmentsAssign')}
              className={`text-white py-2 px-4 rounded-lg  ${
                disableSaveButton
                  ? 'bg-sfl-disabled-grey opacity-25'
                  : 'bg-green-700 hover:bg-green-800'
              }`}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
